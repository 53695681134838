<template>
    <v-container fluid>
        <page-banner links="Abonnement"></page-banner>

        <v-card class="black mb-10" tile>
            <v-card-text class="headline white--text">
                <v-row>
                    <v-col cols="1">
                        <v-img src="/ls_logo_white.svg" width="40px" alt=""></v-img>
                    </v-col>
                    <v-col class="align-self-center ml-n8">
                        LearnSpirit, l'outil qui vous aide à rendre vos formations plus efficaces et qualitatives
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row class="mt-16" v-if="!user.hasValidSubscription">
            <v-card class="rounded-sm mx-auto" elevation="2" width="100%">
                <v-card-title class="justify-center text-h3">Merci d'avoir fait le choix de rejoindre la communauté des utilisateurs LearnSpirit !</v-card-title>
                <v-container fluid class="px-10">
                    <v-card-text class="text-h6 mb-5">
                        <v-row>
                            L’application LearnSpirit vous est proposé en version “Démo”. Il vous est donc possible de la tester gratuitement avec certaines restrictions.
                            Cependant si vous désirez un accès complet aux fonctionnalités de l’application, il sera nécessaire de choisir un des forfaits à votre disposition.
                        </v-row>
                        <v-row class="mt-10">Deux options d’abonnement sont disponibles :</v-row>
                        <v-row class="mt-5">- Licence "Pro" : abonnement avec accès à toutes les fonctionnalités pour un unique utilisateur</v-row>
                        <v-row class="mt-5">- Licence "Entreprise" : abonnement avec accès à toutes les fonctionnalités et permettant le travail collaboratif jusqu’à 3 utilisateurs</v-row>
                    </v-card-text>
                    <stripe-pricing-table
                        :pricing-table-id="prctbl"
                        :publishable-key="publishableKey"
                        :client-reference-id="clientReferenceId"
                        :customer-email="customerEmail"
                    >
                    </stripe-pricing-table>
                </v-container>
            </v-card>
        </v-row>
        <v-row v-else class="mt-16">
            <v-card class="rounded-sm mx-auto" elevation="2" width="100%">
                <v-card-title class="justify-center text-h3">Votre abonnement est validé !</v-card-title>
                <v-container fluid class="px-10">
                    <v-card-text class="text-h6">
                        <v-row>
                            <p>Votre abonnement est valide jusqu'au {{ new Date(user.subscriptionExpires).toLocaleDateString() }} !</p>
                            <p>Pendant toute sa durée, vous pouvez utiliser pleinement l'application et télécharger le résultat de votre travail.</p>
                            <p>Cet abonnement vous autorise à réutiliser vos documents, les modifier, les imprimer et les commercialiser.</p>
                            <p>Vous pouvez maintenant prendre du plaisir à concevoir vos formations.</p>
                        </v-row>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-row>
        <div class="mt-16"></div>
    </v-container>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import { mapActions, mapState } from "vuex";


export default {
    name: "Subscription",

    components: { PageBanner },

    data: () => ({
        publishableKey: process.env.VUE_APP_STRIPE_PK,
        prctbl: process.env.VUE_APP_STRIPE_PRCTBL,

        successURL: process.env.VUE_APP_API_SERVER_URL + "/subscription",
        cancelURL: process.env.VUE_APP_API_SERVER_URL + "/subscription",
    }),

    async mounted() {
        await this.refreshToken();
    },

    computed: {
        ...mapState("settings", ["user"]),

        clientReferenceId: function() {
            return this.user.id;
        },

        customerEmail: function() {
            return this.user.email;
        },

        itemRedirect() {
            return this.$route.query.item;
        },
    },

    methods: {
        ...mapActions("settings", ["refreshToken"]),

        subscribe: function(type) {
            if(type === 1) {
                this.$refs.checkoutRef1.redirectToCheckout();
            } else if(type === 2) {
                this.$refs.checkoutRef2.redirectToCheckout();
            } else if(type === 3) {
                this.$refs.checkoutRef3.redirectToCheckout();
            }
        }
    }
}
</script>

<style scoped>

</style>